.my-modal-body {
    padding: 0px !important;
    position: relative;
    .modal-close-button {
        position: absolute;
        right: 32px;
        top: 32px;
        @media not all and (min-width: 768px) {
            top: 21px;
            right: 21px;
        }
    }
}
