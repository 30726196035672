.gradient {
  background: radial-gradient(circle at 100% 100%, #00130a 0, #00130a 3px, transparent 3px) 0% 0%/4px 4px no-repeat,
  radial-gradient(circle at 0 100%, #00130a 0, #00130a 3px, transparent 3px) 100% 0%/4px 4px no-repeat,
  radial-gradient(circle at 100% 0, #00130a 0, #00130a 3px, transparent 3px) 0% 100%/4px 4px no-repeat,
  radial-gradient(circle at 0 0, #00130a 0, #00130a 3px, transparent 3px) 100% 100%/4px 4px no-repeat,
  linear-gradient(#00130a, #00130a) 50% 50%/calc(100% - 2px) calc(100% - 8px) no-repeat,
  linear-gradient(#00130a, #00130a) 50% 50%/calc(100% - 8px) calc(100% - 2px) no-repeat,
  linear-gradient(135deg, #418f44 0%, transparent 13%);
  border-radius: 4px;
  padding: 5px;
  box-sizing: border-box;
}