@font-face {
    font-family: 'Mulish';
    src: url('./fonts/Mulish-SemiBold.eot');
    src: url('./fonts/Mulish-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Mulish-SemiBold.woff2') format('woff2'),
        url('./fonts/Mulish-SemiBold.woff') format('woff'),
        url('./fonts/Mulish-SemiBold.ttf') format('truetype'),
        url('./fonts/Mulish-SemiBold.svg#Mulish-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('./fonts/Mulish-Medium.eot');
    src: url('./fonts/Mulish-Medium.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Mulish-Medium.woff2') format('woff2'),
        url('./fonts/Mulish-Medium.woff') format('woff'),
        url('./fonts/Mulish-Medium.ttf') format('truetype'),
        url('./fonts/Mulish-Medium.svg#Mulish-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('./fonts/Mulish-ExtraBold.eot');
    src: url('./fonts/Mulish-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Mulish-ExtraBold.woff2') format('woff2'),
        url('./fonts/Mulish-ExtraBold.woff') format('woff'),
        url('./fonts/Mulish-ExtraBold.ttf') format('truetype'),
        url('./fonts/Mulish-ExtraBold.svg#Mulish-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('./fonts/Mulish-Regular.eot');
    src: url('./fonts/Mulish-Regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Mulish-Regular.woff2') format('woff2'),
        url('./fonts/Mulish-Regular.woff') format('woff'),
        url('./fonts/Mulish-Regular.ttf') format('truetype'),
        url('./fonts/Mulish-Regular.svg#Mulish-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('./fonts/Mulish-Black.eot');
    src: url('./fonts/Mulish-Black.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Mulish-Black.woff2') format('woff2'),
        url('./fonts/Mulish-Black.woff') format('woff'),
        url('./fonts/Mulish-Black.ttf') format('truetype'),
        url('./fonts/Mulish-Black.svg#Mulish-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('./fonts/Mulish-Light.eot');
    src: url('./fonts/Mulish-Light.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Mulish-Light.woff2') format('woff2'),
        url('./fonts/Mulish-Light.woff') format('woff'),
        url('./fonts/Mulish-Light.ttf') format('truetype'),
        url('./fonts/Mulish-Light.svg#Mulish-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('./fonts/Mulish-Bold.eot');
    src: url('./fonts/Mulish-Bold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Mulish-Bold.woff2') format('woff2'),
        url('./fonts/Mulish-Bold.woff') format('woff'),
        url('./fonts/Mulish-Bold.ttf') format('truetype'),
        url('./fonts/Mulish-Bold.svg#Mulish-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}




body {
    position: relative;
    font-size: 18px;
    font-family: "Mulish", serif;

    * {
        box-sizing: border-box;

        &::before {
            box-sizing: border-box;
        }

        &::after {
            box-sizing: border-box;
        }
    }

    &.no-scroll {
        overflow-y: hidden;
    }

    a {
        color: inherit;
    }

    button {
        font-family: "Mulish", serif;
    }

    input {
        font-family: "Mulish", serif;

        &::placeholder {
            color: var(--text-color);
            font-family: "Mulish", serif;
        }
    }

    textarea {
        font-family: "Mulish", serif;

        &::placeholder {
            color: var(--text-color);
            font-family: "Mulish", serif;
        }
    }
}

.wrapper {
    width: 1920px;
    margin: 0 auto;
    max-width: 100%;
    position: relative;
    min-height: 100vh;
    overflow: hidden;

    &--sticky {
        overflow: visible;
    }
}

.inside {
    width: 1448px;
    max-width: 100%;
    padding: 0 20px;
    text-align: center;
    margin: 0 auto;
    box-sizing: border-box;
    /* z-index: 10; */

    @media not all and (min-width: 1280px) {
        padding: 0 48px;
    }

    @media not all and (min-width: 768px) {
        padding: 0 16px;
    }

    &--full {
        width: 100%;
        padding: 0;
    }
}

.usn {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.mob {
    display: none !important;
}

.hidden-checkbox {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    clip: rect(0 0 0 0);
    overflow: hidden;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

section {
    padding: 1px 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}



header {
    box-shadow: 0px 4px 30px rgba(33, 96, 253, 0.05);
}


.button {
    padding: 11px 23px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    font-size: 16px;
    color: #fff;
    border: 1px solid #418f44;
    border-radius: 4px;
    background: #418f44;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
        color: #418f44;
        background: none;
    }

    &--type2 {
        color: #418f44;
        background: none;

        &:hover {
            color: #fff;
            background: #418f44;
        }
    }

    &.pulsate {
        outline: 6px solid #c6dec7;
        animation: pulsate 1s infinite;
    }

    &.pulsate-dark {
        outline: 6px solid #c6dec7;
        animation: pulsate-dark 1s infinite;
    }
}

@keyframes pulsate {
    0% {
        outline-color: #fff;
    }

    50% {
        outline-color: #c6dec7;
    }

    100% {
        outline-color: #fff;
    }
}

@keyframes pulsate-dark {
    0% {
        outline-color: #00130a;
    }

    50% {
        outline-color: #c6dec7;
    }

    100% {
        outline-color: #00130a;
    }
}

.border-design {
    //border: 1px solid #F1F1F1;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        left: -1px;
        top: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        z-index: 1;
        background: url('../../../public/img/border-design.svg') no-repeat center 0;
        background-size: 100%;
    }

    &--type2 {
        &::before {
            background: url('../../../public/img/border-design2.svg') no-repeat center 0;
        }
    }

    &--type3 {
        &::before {
            background: url('../../../public/img/border-design3.svg') no-repeat center 0;
        }
    }

    &--type4 {
        &::before {
            background: url('../../../public/img/border-design4.svg') no-repeat center 0;
        }
    }
}


.checkbox-box {
    position: relative;
    min-width: 20px;
    width: 20px;
    height: 20px;
    border: 1px solid #418F44;

    &::before {
        content: "";
        position: absolute;
        width: 11px;
        height: 10px;
        left: 50%;
        top: 50%;
        background: url("data:image/svg+xml,%3Csvg width='11' height='10' viewBox='0 0 11 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.77812 10.2022L0 6.00359L1.11516 4L3.74016 6.91654L9.96562 0.502813L11.116 1.46563L3.77812 10.2022Z' fill='white'/%3E%3C/svg%3E") no-repeat center 0;
        transform: translate(-50%, -50%);
        opacity: 0;
    }
}

input:checked~.checkbox-box {
    background: #418F44;

    &::before {
        opacity: 1;
    }
}

.form-shadow {
    /* filter: drop-shadow(0px -4px 30px rgba(33, 96, 253, 0.05)) drop-shadow(0px 4px 30px rgba(33, 96, 253, 0.05)); */
}

.swiper-review {
    @media not all and (min-width: 1280px) {
        overflow: visible !important;
    }

    .slide {
        @media not all and (min-width: 1280px) {
            width: 453px !important;
        }

        @media not all and (min-width: 768px) {
            width: 100% !important;
        }
    }

    .swiper-pagination {
        display: none;

        @media not all and (min-width: 1280px) {
            margin-top: 24px;
            display: flex;
            justify-content: center;
            position: static;
        }

        @media not all and (min-width: 768px) {
            margin-top: 14px;
            display: flex;
            justify-content: center;
            position: static;
        }
    }

    .swiper-pagination-bullet {
        background: #e3eee3;

        &-active {
            background: #418F44;
        }
    }
}


.popup {
    font-family: "Mulish", serif;
    //position: relative;
}

.form-review-error {
    color: red;
}