.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  flex-direction: column;
}

.loader {
  width: 40px;
  aspect-ratio: 1;
  color: #514b82;
  background: repeating-conic-gradient(from -47deg, #0000 0deg, #418f44 1deg 91deg, #0000 94deg 180deg);
  display: flex;
  animation: l12-0 2s infinite linear;
}
.loader-text {
  margin-top: 50px;
}
.loader::before,
.loader::after {
  content: "";
  flex: 1;
  background: #418f44;
  clip-path: polygon(0 0, 100% 50%, 0 100%);
  animation: l12 1s infinite alternate;
  transform-origin: bottom left;
}
.loader::after {
  clip-path: polygon(100% 0, 100% 100%, 0 50%);
  transform-origin: top right;
}
@keyframes l12-0 {
  0%, 49.9% { transform: scaleX(1); }
  50%, 100% { transform: scaleX(-1); }
}
@keyframes l12 {
  0%, 20% { transform: rotate(0deg); }
  80%, 100% { transform: rotate(-270deg); }
}